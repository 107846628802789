/* eslint-disable @typescript-eslint/no-unused-vars */
import { z } from 'zod'

import type { Equal, ExpectTrue } from '../types/validation'

/* Typescript exports */
export type LinkTarget = '_blank' | '_self'

export type ContentLink = {
  href: string
  target?: LinkTarget
  rel?: string
}

/* Schemas */
export const LinkTargetSchema = z.enum(['_blank', '_self'])

export const ContentLinkSchema = z.object({
  href: z.string(),
  target: LinkTargetSchema.optional(),
  rel: z.string().optional(),
})

export const ContentLinkWithTargetBlankSchema = z.object({
  href: z.string(),
  target: LinkTargetSchema.optional(),
  rel: z.string().optional(),
  targetBlank: z.boolean().optional(),
})

/* Schemas validation */
type TestContentLink = ExpectTrue<
  Equal<ContentLink, z.infer<typeof ContentLinkSchema>>
>
