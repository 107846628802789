/* eslint-disable @typescript-eslint/no-unused-vars */
import { z } from 'zod'

import type { Equal, ExpectTrue } from '../types/validation'

/* TypeScript */
export type SimpleImage = {
  src: string
  alt: string
}

export type Image = {
  src: string
  srcMobile: string
  alt: string
  width: number
  height: number
  widthMobile?: number
  heightMobile?: number
}

/* Schemas */
export const SimpleImageSchema = z.object({
  src: z.string(),
  alt: z.string(),
})

export const ImageSchema = z.object({
  src: z.string(),
  srcMobile: z.string(),
  alt: z.string(),
  width: z.number(),
  height: z.number(),
  widthMobile: z.number().optional(),
  heightMobile: z.number().optional(),
})

/* Schemas validation */
type TestSimpleImage = ExpectTrue<
  Equal<SimpleImage, z.infer<typeof SimpleImageSchema>>
>
type TestImage = ExpectTrue<Equal<Image, z.infer<typeof ImageSchema>>>
