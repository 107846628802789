/* eslint-disable @typescript-eslint/no-unused-vars */
import { z } from 'zod'

import type { Equal, ExpectTrue } from '../types/validation'

import { type ContentLink, ContentLinkSchema } from './content-link'

/* TypeScript */
export type CTALink = {
  link: ContentLink
  label: string
}

/* Schemas */
export const CTALinkSchema = z.object({
  link: ContentLinkSchema,
  label: z.string(),
})

/* Schemas validation */
type TestCTALink = ExpectTrue<Equal<CTALink, z.infer<typeof CTALinkSchema>>>
